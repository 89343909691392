import React from 'react';
import { ProductCategoryListingBlockType } from '../../types';
import { CAROUSEL_PRESENTATION } from '../../constants/cmsTypes';
import { SubHeading } from '../../components/SubHeading/SubHeading';
import { DepictCategoryPage } from '../../components/DepictCategoryPage/DepictCategoryPage';
import { ContainerRoot } from '../../components/Container/styles';
import { useSelector } from 'react-redux';
import { Container } from '../../components/Container/Container';

const ProductCategoryListingBlock: React.FC<ProductCategoryListingBlockType> = (
  props
) => {
  const {
    id,
    presentationMode,
    heading,
    hasFirstHeading,
    displayOnMobile,
    displayOnDesktop,
    categoryId,
  } = props;

  const currentPage =
    useSelector((store: any) => store?.editorial?.data[id || '']) || props.page;
  const locale = useSelector((store: any) => store?.viewState?.market);
  const currency = useSelector(
    (store: any) => store?.bootstrap?.data?.currencyCode
  );

  return (
    <ContainerRoot
      as="section"
      noPadding
      data-litium-block-id={id}
      swipe={presentationMode === CAROUSEL_PRESENTATION}
      displayOnMobile={displayOnMobile}
      displayOnDesktop={displayOnDesktop}
    >
      {heading && (
        <Container>
          <SubHeading centerMobile as={hasFirstHeading ? 'h1' : 'h2'}>
            {heading}
          </SubHeading>
        </Container>
      )}
      <DepictCategoryPage
        page={{
          ...currentPage,
          categoryId,
        }}
        currency={currency}
        localeCode={locale}
        layout="slider-without-filters"
      />
    </ContainerRoot>
  );
};

export default ProductCategoryListingBlock;
