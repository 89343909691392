import {
  CategoryPage,
  DepictProductCardProps,
  EmbeddedNumProducts,
  ReactContentBlocksByRow,
} from '@depict-ai/react-ui';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DepictProduct, ProductCardProps, MediaType } from 'types';

import { parseProductData } from 'helpers/depict';

import { setViewstate } from 'store/actions/actions';

import { useCalculateColumnSpan } from 'hooks/useCalculateColumnSpan';

import { ProductCard } from 'components/ProductCard/ProductCard';
import DepictProductCardSkeleton from 'components/DepictProductCardSkeleton/DepictProductCardSkeleton';
import Video from 'components/Video/Video';

import { Container } from './styles';
import { START_PAGE } from 'constants/cmsTypes';

const Component = ProductCard as React.FC<ProductCardProps>;

export type DepictCategoryPageProps = {
  page: {
    title: string;
    body: string;
    breadcrumb: {
      title: string;
      type: string;
      url: string;
      data?: object;
    }[];
    listingContent?: {
      link: string;
      video: MediaType;
      image: MediaType;
    }[];
    categorySystemId?: string;
    categoryId?: string;
    hideShopBy: boolean;
    shopByLabel: string;
    productsLabel: string;
    type: string;
  };
  currency: string;
  localeCode?: string;
  childCategories?: object[];
  layout?: 'slider' | 'grid' | 'slider-without-filters';
};

export const DepictCategoryPage: React.FC<DepictCategoryPageProps> = (
  props
) => {
  const dispatch = useDispatch();

  const { page, currency, localeCode = 'sv', layout = 'grid' } = props;
  const { listingContent, type } = page;

  const categorySystemId = page.categorySystemId || page.categoryId;

  useEffect(() => {
    dispatch(setViewstate('categorySystemId', categorySystemId));
  }, [categorySystemId]);

  if (type === START_PAGE) {
  }

  const MoodContent = ({ link, video, image }) => {
    return (
      <Video
        loop
        hideButton
        playOnViewport
        m="20px 0"
        inheritHeight
        relative
        link={link}
        src={video?.urlPath}
        cover={image?.urlPath}
        playsInline
        muted
        autoplay
      />
    );
  };

  const contentBlocks: ReactContentBlocksByRow = [];
  let desiredRow = 1; // Start with the 2rd row
  const spanColumns = useCalculateColumnSpan() as number;

  if (listingContent) {
    // Set up listing mood content (video or image)
    for (let i = 0; i < listingContent.length; i++) {
      const spanRows = 2;
      const position = 'center' as const;

      contentBlocks[desiredRow] = {
        content: () => <MoodContent {...listingContent[i]} />,
        spanColumns,
        spanRows,
        position,
      };

      desiredRow *= 2 + 2; // Multiply desired row by X to get it showing every X rows
    }
  }

  return (
    <Container>
      <CategoryPage
        stateKey={categorySystemId}
        layout={layout}
        listingQuery={{
          type: 'externalId',
          id: categorySystemId,
        }}
        contentBlocksByRow={contentBlocks}
        productCard={(product: DepictProductCardProps<DepictProduct>) => {
          const { display } = product;

          const productProps = parseProductData(display, localeCode, currency);

          if (!display || !productProps) return <DepictProductCardSkeleton />;

          return (
            <Component
              key={productProps.key}
              product={{
                ...productProps,
              }}
            />
          );
        }}
        columnsAtSize={[[2, 831], [3, 1024], [4]]}
        showBreadcrumbs={false}
        showQuicklinks={false}
        categoryTitlePlugin={EmbeddedNumProducts}
      />
    </Container>
  );
};
