import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';
import { compose, get, isEmpty } from 'lodash/fp';
import { withRouter } from 'next/router';
import { ContainerRoot } from '../../components/Container/styles';
import { connect } from '../../store/store';
import {
  productsFromListRequest,
  productFilterRequest,
  removeViewstate,
  setViewstate,
} from '../../store/actions/actions';
import { ProductListWithFilters } from '../../components/ProductListWithFilters/ProductListWithFilters';
import { ProductList } from '../../components/ProductList/ProductList';
import { SwipeProductList } from '../../components/ProductList/SwipeProductList';
import { Conditional } from '../../components/Conditional/Conditional';
import { SubHeading } from '../../components/SubHeading/SubHeading';
import {
  NON_FILTER_LIST_PRESENTATION,
  FILTER_LIST_PRESENTATION,
  CAROUSEL_PRESENTATION,
} from '../../constants/cmsTypes';

class ProductListListingBlockComponent extends Component {
  componentDidMount() {
    const { router, productListId, activeCategory, market } = this.props;
    const pageId = get(['query', 'id'], router);

    if (activeCategory !== pageId) {
      this.props.removeViewstate('filter');
      this.props.setViewstate('activeCategory', pageId);
    }

    if (productListId) {
      this.props.productsFromListRequest({
        productListId,
        market: router.locale,
      });
      this.props.productFilterRequest({ productListId, market: router.locale });
    }
  }

  componentDidUpdate(prevProps) {
    const { requestEditorial, productListId, market, router } = this.props;

    // Reload the products is locale has changed
    if (prevProps.router.locale !== router.locale && productListId) {
      this.props.productsFromListRequest({
        productListId,
        market: router.locale,
      });
    }

    // If reloading currentpage when discount code isExists, also reload products when editorial request is completed
    if (
      prevProps.requestEditorial.status === 'LOADING' &&
      requestEditorial.status === 'SUCCESS' &&
      productListId
    ) {
      this.props.productsFromListRequest({
        productListId,
        market: router.locale,
      });
    }
  }

  render() {
    const {
      heading,
      productListId,
      availableProductFilters,
      presentationMode,
      autoplayDelay,
      products,
      request,
      productFilter,
      hasFirstHeading,
      productListingContent,
      displayOnDesktop,
      displayOnMobile,
    } = this.props;

    if (!productListId) return false;

    return (
      <BlockRoot
        as="section"
        data-litium-block-id={get('draftId', this.props)}
        swipe={presentationMode === CAROUSEL_PRESENTATION}
        displayOnMobile={displayOnMobile}
        displayOnDesktop={displayOnDesktop}
      >
        <Conditional show={!!heading}>
          <SubHeading centerMobile as={hasFirstHeading ? 'h1' : 'h2'}>
            {heading}
          </SubHeading>
        </Conditional>

        <Conditional
          show={
            !isEmpty(products) && presentationMode === FILTER_LIST_PRESENTATION
          }
        >
          <ProductListWithFilters
            products={products}
            filters={productFilter}
            request={request}
            availableProductFilters={availableProductFilters}
            productListContent={productListingContent}
          />
        </Conditional>

        <Conditional
          show={
            !isEmpty(products) &&
            presentationMode === NON_FILTER_LIST_PRESENTATION
          }
        >
          <ProductList
            products={products}
            productListContent={productListingContent}
          />
        </Conditional>

        <Conditional
          show={
            !isEmpty(products) && presentationMode === CAROUSEL_PRESENTATION
          }
        >
          <SwipeProductList products={products} autoplayDelay={autoplayDelay} />
        </Conditional>
      </BlockRoot>
    );
  }
}

ProductListListingBlockComponent.defaultProps = {
  heading: undefined,
  productListId: undefined,
  activeCategory: undefined,
  availableProductFilters: [],
  autoplayDelay: undefined,
  products: {},
  request: {},
  hasFirstHeading: false,
  productFilter: {},
  requestEditorial: {},
  productListingContent: [],
  displayOnMobile: true,
  displayOnDesktop: true,
};

ProductListListingBlockComponent.propTypes = {
  router: PropTypes.object.isRequired,
  productsFromListRequest: PropTypes.func.isRequired,
  setViewstate: PropTypes.func.isRequired,
  removeViewstate: PropTypes.func.isRequired,
  presentationMode: PropTypes.string.isRequired,
  heading: PropTypes.string,
  productListId: PropTypes.string,
  availableProductFilters: PropTypes.arrayOf(PropTypes.string),
  autoplayDelay: PropTypes.number,
  activeCategory: PropTypes.string,
  products: PropTypes.object,
  request: PropTypes.object,
  market: PropTypes.string.isRequired,
  hasFirstHeading: PropTypes.bool,
  productFilterRequest: PropTypes.func.isRequired,
  productFilter: PropTypes.object,
  requestEditorial: PropTypes.object,
  productListingContent: PropTypes.array,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool,
};

export const ProductListListingBlock = compose(withRouter, connect)(
  ProductListListingBlockComponent,
  {
    productsFromListRequest,
    productFilterRequest,
    setViewstate,
    removeViewstate,
  },
  (store, props) => ({
    activeCategory: get(['viewState', 'activeCategory'], store),
    products: get(['products', 'data', get('productListId', props)], store),
    request: get(['products', 'request', get('productListId', props)], store),
    productFilter: get(['productFilter', 'data', props?.productListId], store),
    market: get(['viewState', 'market'], store),
    requestEditorial: get(
      ['editorial', 'request', props.router.query.id],
      store
    ),
  })
);

const BlockRoot = styled(ContainerRoot)`
  min-height: 360px;

  ${media.M`
    min-height: 680px;
  `};

  ${(props) =>
    props.swipe &&
    css`
      @media screen and (max-width: 479px) {
        padding: 0;
      }
    `}
  ${(props) => props.style && props.style}
`;
